// ** Initial State
const initialState = {
  consignees: []
  }
  
  const consignee = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_CONSIGNEES':
        return { ...state, consignees: action.consignees }
      default:
        return { ...state }
    }
  }

  export default consignee