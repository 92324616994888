// ** Initial State
const initialState = {
    allOrder: [],
    manifests: [],
    data: [],
    destinations: [],
    total: 1,
    params: {},
  selectedOrder: {},
  createdOrder: {},
    airwayBillNo: null,
    isLoading: false,
    creatingRecord: false,
    recordCreated: false,
    updatingRecord: false,
    recordUpdated: false
  }
  
  const order = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_ORDER':
        return { ...state, isLoading: false, allOrder: action.data }
      case 'GET_ORDER':
        return { ...state, selectedOrder: action.selectedOrder }
      case 'ADD_ORDER':
        return { ...state, createdOrder: action.data, recordCreated: true, creatingRecord: false }
      case 'EDIT_ORDER':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'CHANGE_ORDER_STATUS':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'RESET_SELECTED_ORDER':
          return { ...state, selectedOrder: null }
      case 'DELETE_ORDER':
        return { ...state, isLoading: false }
      case 'SET_ORDER_LOADING':
          return { ...state, isLoading: true } 
      case 'SET_ORDER_CREATING':
          return { ...state, recordCreated: false, creatingRecord: true } 
      case 'SET_ORDER_UPDATING':
          return { ...state, recordUpdated: false, updatingRecord: true } 
      case 'SET_RESET_ORDER_CREATED':
          return { ...state, recordCreated: false } 
      case 'SET_RESET_ORDER_UPDATED':
          return { ...state, recordUpdated: false, updatingRecord: false } 
      case 'GET_AIR_WAY_BILL_NO':
          return { ...state, airwayBillNo: action.airwayBillNo }
      case 'GET_MANIFESTS':
        return { ...state, manifests: action.manifests }
      case 'GET_ALL_ORDER_DESTINATIONS':
        return { ...state, destinations: action.destinations }
      default:
        return { ...state }
    }
  }
  export default order