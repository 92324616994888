// ** Initial State
const initialState = {
  shippers: []
  }
  
  const shipper = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_SHIPPERS':
        return { ...state, shippers: action.shippers }
      default:
        return { ...state }
    }
  }

  export default shipper