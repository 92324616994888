// ** Initial State
const initialState = {
    allCustomers: [],
    data: [],
    total: 1,
    params: {},
    selectedCustomer: null,
    selectedShipper: null,
    loadingShipper: null,
    selectedConsigner: null,
    loadingConsigner: null,
    isLoading: false,
    creatingRecord: false,
    recordCreated: false,
    updatingRecord: false,
    recordUpdated: false
  }
  
  const customers = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_CUSTOMERS':
        return { ...state, isLoading: false, allCustomers: action.data }
      case 'GET_CUSTOMER':
        return { ...state, selectedCustomer: action.selectedCustomer }
      case 'ADD_CUSTOMER':
        return { ...state, recordCreated: true, creatingRecord: false }
      case 'EDIT_CUSTOMER':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'CHANGE_CUSTOMER_STATUS':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'RESET_SELECTED_CUSTOMER':
          return { ...state, selectedCustomer: null }
      case 'DELETE_CUSTOMER':
        return { ...state, isLoading: false }
      case 'SET_CUSTOMER_LOADING':
          return { ...state, isLoading: true } 
      case 'SET_CUSTOMER_CREATING':
          return { ...state, recordCreated: false, creatingRecord: true } 
      case 'SET_CUSTOMER_UPDATING':
          return { ...state, recordUpdated: false, updatingRecord: true } 
      case 'SET_RESET_CUSTOMER_CREATED':
          return { ...state, recordCreated: false } 
      case 'SET_RESET_CUSTOMER_UPDATED':
          return { ...state, recordUpdated: false, updatingRecord: false } 
      case 'SET_SHIPPER_LOADING':
          return { ...state, loadingShipper: true } 
      case 'SET_CONSIGNER_LOADING':
          return { ...state, loadingConsigner: true } 
      case 'GET_SHIPPER':
          return { ...state, loadingShipper: false, selectedShipper: action.selectedShipper }
      case 'GET_CONSIGNER':
          return { ...state, loadingConsigner: false, selectedConsigner: action.selectedConsigner }
  
      default:
        return { ...state }
    }
  }
  export default customers