// ** Initial State
const initialState = {
    allUsers: [],
    data: [],
    total: 1,
    params: {},
    selectedUser: null,
    isLoading: false,
    creatingRecord: false,
    recordCreated: false,
    updatingRecord: false,
    recordUpdated: false
  }
  
  const users = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_USERS':
        return { ...state, isLoading: false, allUsers: action.data }
      case 'GET_USER':
        return { ...state, selectedUser: action.selectedUser }
      case 'ADD_USER':
        return { ...state, recordCreated: true, creatingRecord: false }
      case 'EDIT_USER':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'CHANGE_USER_STATUS':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'RESET_SELECTED_USER':
          return { ...state, selectedUser: null }
      case 'DELETE_USER':
        return { ...state, isLoading: false }
      case 'SET_USER_LOADING':
          return { ...state, isLoading: true } 
      case 'SET_USER_CREATING':
          return { ...state, recordCreated: false, creatingRecord: true } 
      case 'SET_USER_UPDATING':
          return { ...state, recordUpdated: false, updatingRecord: true } 
      case 'SET_RESET_USER_CREATED':
          return { ...state, recordCreated: false } 
      case 'SET_RESET_USER_UPDATED':
          return { ...state, recordUpdated: false, updatingRecord: false } 
      default:
        return { ...state }
    }
  }
  export default users