// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './reducers/auth'
import user from './reducers/user'
import goods from './reducers/goods'
import customer from './reducers/customer'
import order from './reducers/order'
import manifest from './reducers/manifest'
import shipper from './reducers/shipper'
import consignee from './reducers/consignee'


const rootReducer = {
  auth,
  user,
  goods,
  customer,
  order,
  manifest,
  shipper,
  consignee,
  navbar,
  layout
}

export default rootReducer
