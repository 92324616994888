// ** Initial State
const initialState = {
  manifests: [],
  selectedManifest: {},
  updated: false
  }
  
  const manifest = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_MANIFESTS':
        return { ...state, manifests: action.manifests }
      case 'GET_MANIFEST':
        return { ...state, selectedManifest: action.selectedManifest }
      case 'SET_UPDATED':
        return { ...state, updated: true }
      case 'RESET_UPDATED':
        return { ...state, updated: false}
      default:
        return { ...state }
    }
  }


  export default manifest