// ** Initial State
const initialState = {
    allGoods: [],
    data: [],
    total: 1,
    params: {},
    selectedGoods: null,
    isLoading: false,
    creatingRecord: false,
    recordCreated: false,
    updatingRecord: false,
    recordUpdated: false
  }
  
  const goods = (state = initialState, action) => {
    switch (action.type) {
      case 'GET_ALL_GOODS':
        return { ...state, isLoading: false, allGoods: action.data }
      case 'GET_GOODS':
        return { ...state, selectedGoods: action.selectedGoods }
      case 'ADD_GOODS':
        return { ...state, recordCreated: true, creatingRecord: false }
      case 'EDIT_GOODS':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'CHANGE_GOODS_STATUS':
        return { ...state, recordUpdated: true, updatingRecord: false }
      case 'RESET_SELECTED_GOODS':
          return { ...state, selectedGoods: null }
      case 'DELETE_GOODS':
        return { ...state, isLoading: false }
      case 'SET_GOODS_LOADING':
          return { ...state, isLoading: true } 
      case 'SET_GOODS_CREATING':
          return { ...state, recordCreated: false, creatingRecord: true } 
      case 'SET_GOODS_UPDATING':
          return { ...state, recordUpdated: false, updatingRecord: true } 
      case 'SET_RESET_GOODS_CREATED':
          return { ...state, recordCreated: false } 
      case 'SET_RESET_GOODS_UPDATED':
          return { ...state, recordUpdated: false, updatingRecord: false } 
      default:
        return { ...state }
    }
  }
  export default goods